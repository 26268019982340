<template>
  <div class="content">
    <div class="top-box">
      <img class="top-img" :onerror="defaultImg" :src="detailList.image" />
      <div class="top-list">
        <div class="name">{{ detailList.name }}</div>
        <div class="detail">
          <div class="detail-left">职业</div>
          <div class="detail-right">{{ detailList.typeName }}</div>
        </div>
        <div class="detail">
          <div class="detail-left">民族</div>
          <div class="detail-right">{{ detailList.nation }}</div>
        </div>
        <div class="detail">
          <div class="detail-left">籍贯</div>
          <div class="detail-right">{{ detailList.job }}</div>
        </div>
        <div class="detail">
          <div class="detail-left">毕业学校</div>
          <div class="detail-right">{{ detailList.school }}</div>
        </div>
      </div>
    </div>
    <div class="text">{{ detailList.descript }}</div>
    <div class="drama-list">
      <div class="drama-title">
        <div class="title-left"></div>
        <div class="title-right">话剧作品</div>
      </div>
      <div
        v-for="(item, index) in detailList.repertoireList"
        :key="item.id"
        class="drama-content"
      >
        <div @click="toOpus(item)" class="drama-left">
          {{ item.repertoireName }}
        </div>
        <!-- <div v-if="page == 4" class="drama-right">
          <div style="color: #909399">{{ "饰：" }}</div>
          {{ item.role }}
        </div> -->
        <div class="drama-right">
          <div v-if="!judgeList.includes(item.role)" style="color: #909399">
            {{ "饰：" }}
          </div>
          {{ item.role }}
        </div>
        <!-- <div v-else class="drama-right">{{ item.role }}</div> -->
      </div>
    </div>
    <div class="honor-list">
      <div class="honor-title">
        <div class="title-left"></div>
        <div class="title-right">荣誉记录</div>
      </div>
      <div v-for="item in honorList" :key="item" class="honor-content">
        {{ item }}
      </div>
    </div>
    <div class="repertoire-list">
      <div class="repertoire-title">
        <div class="title-left"></div>
        <div class="title-right">剧目相册</div>
      </div>
      <div
        v-if="detailList.pictures && detailList.pictures.length !== 0"
        class="content-list"
      >
        <div class="content-left">
          <el-image
            class="img1"
            :onerror="defaultImg"
            :src="
              detailList.pictures && detailList.pictures[4]
                ? detailList.pictures[4]
                : ''
            "
            :preview-src-list="[
              detailList.pictures && detailList.pictures[4]
                ? detailList.pictures[4]
                : '',
            ]"
          ></el-image>
          <el-image
            class="img2"
            :onerror="defaultImg"
            :src="
              detailList.pictures && detailList.pictures[1]
                ? detailList.pictures[1]
                : ''
            "
            :preview-src-list="[
              detailList.pictures && detailList.pictures[1]
                ? detailList.pictures[1]
                : '',
            ]"
          ></el-image>
          <el-image
            class="img3"
            :onerror="defaultImg"
            :src="
              detailList.pictures && detailList.pictures[2]
                ? detailList.pictures[2]
                : ''
            "
            :preview-src-list="[
              detailList.pictures && detailList.pictures[2]
                ? detailList.pictures[2]
                : '',
            ]"
          ></el-image>
        </div>
        <div class="content-right">
          <el-image
            class="img4"
            :onerror="defaultImg"
            :src="
              detailList.pictures && detailList.pictures[3]
                ? detailList.pictures[3]
                : ''
            "
            :preview-src-list="[
              detailList.pictures && detailList.pictures[3]
                ? detailList.pictures[3]
                : '',
            ]"
          ></el-image>
          <el-image
            class="img5"
            :onerror="defaultImg"
            :src="
              detailList.pictures && detailList.pictures[0]
                ? detailList.pictures[0]
                : ''
            "
            :preview-src-list="[
              detailList.pictures && detailList.pictures[0]
                ? detailList.pictures[0]
                : '',
            ]"
          ></el-image>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import { getDetail } from "@/service/PeopleEntertainers";
import { getSystemDict } from "@/service/system";
export default {
  data() {
    return {
      defaultImg:
        'this.src="' + require("../../../assets/imgs/defaultDiagram.jpg") + '"',
      list: [],
      honorList: [],
      filtList: [
        {
          id: 1,
          name: "话剧作品",
          refName: "topic",
        },
        {
          id: 2,
          name: "人物动态",
          refName: "dynamic",
        },
        {
          id: 3,
          name: "其他作品",
          refName: "other",
        },
        {
          id: 4,
          name: "荣誉记录",
          refName: "honor",
        },
      ],
      dynamicList: [],
      detailList: {},
      page: "",
      judgeList: [],
    };
  },
  created() {
    this.getDetail(this.$route.query.id);
    this.pageFilter(this.$route.query.page);
    this.page = this.$route.query.page;
    this.$nextTick(() => {
      this.refList = this.$refs;
    });
    getSystemDict({ type: "check-role" }).then((res) => {
      this.judgeList = res.data.map((item) => item.value);
    });
  },
  mounted() {
    const isFirstVisit = sessionStorage.getItem("isFirstVisit_B") === null;

    if (isFirstVisit) {
      // 设置标记，表示B页面已经访问过一次
      sessionStorage.setItem("isFirstVisit_B", "false");

      // 执行刷新操作
      window.location.reload();
    }
  },
  methods: {
    toOpus(item) {
      this.$router.push({
        path: `/culture/collection/detail/${item.repertioireId}`,
        query: {
          from: "dc",
        },
      });
    },
    convertToPlainText(html) {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText || "";
    },
    async getDetail(id) {
      const { data } = await getDetail(id);
      this.detailList = data;

      if (typeof data.artwork === "string" && data.artwork !== "") {
        this.detailList.artwork = JSON.parse(data.artwork);
      }
      if (data.pictures) {
        this.detailList.pictures = data.pictures.split(",");
      }
      if (data.honor !== "") {
        this.honorList = data.honor.split("\n");
        console.log(this.honorList);
      }
    },
    filtClick({ id, refName }) {
      this.selectedItemId = id;
      this.refList[refName].scrollIntoView({ behavior: "smooth" });
    },
    pageFilter(page) {
      if (page == 1) {
        this.list = ["人艺人", "创始人", "创始人资料"];
        return;
      } else if (page == 2) {
        this.list = ["人艺人", "编剧", "编剧资料"];
        return;
      } else if (page == 3) {
        this.list = ["人艺人", "导演", "导演资料"];
        return;
      } else if (page == 4) {
        this.list = ["人艺人", "演员", "演员资料"];
        return;
      } else if (page == 5) {
        this.list = ["人艺人", "设计", "设计资料"];
        return;
      } else if (page == 6) {
        this.list = ["人艺人", "舞美", "舞美资料"];
        return;
      } else {
        this.list = ["人艺人", "详情"];
        return;
      }
    },
  },
};
</script>
<style scoped lang="less">
.content {
  margin-top: 0.5333rem;
  margin-bottom: 0.5333rem;
  .top-box {
    height: 4.2667rem;
    display: flex;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    .top-img {
      height: 4.2667rem;
      width: 3.2rem;
    }
    .top-list {
      width: 4.4333rem;
      height: 4.2667rem;
      margin-left: 0.4rem;
      .name {
        color: #212122;
        font-size: 0.4533rem;
        font-weight: 800;
      }
      .detail {
        margin-top: 0.4rem;
        display: flex;
        .detail-left {
          width: 1.3867rem;
          height: 0.5067rem;
          background: #f8f8fa;
          color: #909399;
          font-size: 0.2667rem;
          font-weight: 400;
          line-height: 0.5067rem;
          text-align: center;
        }
        .detail-right {
          font-size: 0.3467rem;
          color: #212122;
          font-weight: 500;
          height: 0.5067rem;
          line-height: 0.5067rem;
          margin-left: 0.2667rem;
        }
      }
    }
  }
  .text {
    margin-top: 0.2667rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    font-size: 0.3467rem;
    font-weight: 400;
    color: #212122;
    line-height: 0.5867rem;
  }
  .drama-list {
    margin-top: 0.8rem;
    width: 7.7067rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    .drama-title {
      height: 0.4rem;
      display: flex;
      .title-left {
        background: #21424b;
        width: 0.08rem;
        height: 0.36rem;
      }
      .title-right {
        margin-left: 0.24rem;
        color: #212122;
        font-size: 0.4rem;
        line-height: 0.5rem;
        font-weight: 800;
      }
    }
    .drama-content {
      margin-top: 0.4rem;
      display: flex;
      .drama-left {
        color: #f9680d;
        font-size: 0.3467rem;
        font-weight: 400;
        width: 3rem;
      }
      .drama-right {
        font-size: 0.3467rem;
        font-weight: 400;
        color: #333;
        margin-left: 2.6667rem;
        width: 3rem;
        text-align: left;
        display: flex;
      }
    }
  }
  .honor-list {
    margin-top: 0.8rem;
    width: 7.7067rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    .honor-title {
      height: 0.4rem;
      display: flex;
      .title-left {
        background: #21424b;
        width: 0.08rem;
        height: 0.36rem;
      }
      .title-right {
        margin-left: 0.24rem;
        color: #212122;
        font-size: 0.4rem;
        line-height: 0.5rem;
        font-weight: 800;
      }
    }
    .honor-content {
      font-size: 0.32rem;
      font-weight: 400;
      color: #212122;
      margin-top: 0.2667rem;
    }
  }
  .repertoire-list {
    margin-top: 0.8rem;
    width: 9.2rem;
    margin-bottom: 0.8rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    .repertoire-title {
      height: 0.4rem;
      display: flex;
      .title-left {
        background: #21424b;
        width: 0.08rem;
        height: 0.36rem;
      }
      .title-right {
        margin-left: 0.24rem;
        color: #212122;
        font-size: 0.4rem;
        line-height: 0.5rem;
        font-weight: 800;
      }
    }
    .content-list {
      margin-top: 0.4rem;
      height: 14.6333rem;
      display: flex;
      .content-left {
        width: 4.48rem;
        .img1 {
          width: 4.48rem;
          height: 4.36rem;
          object-fit: contain;
        }
        .img2 {
          width: 4.48rem;
          height: 6.6933rem;
          object-fit: contain;
          margin-top: 0.24rem;
        }
        .img3 {
          margin-top: 0.24rem;
          width: 4.48rem;
          height: 3.36rem;
          object-fit: contain;
        }
      }
      .content-right {
        width: 4.48rem;
        margin-left: 0.24rem;
        .img4 {
          width: 4.48rem;
          height: 6.6933rem;
          object-fit: contain;
        }
        .img5 {
          margin-top: 0.24rem;
          width: 4.48rem;
          height: 6.6933rem;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
